import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description = '', lang = 'en', meta = [], title = null }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const pageTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;
  const { siteUrl } = site.siteMetadata;
  const image = `${siteUrl}/images/atala-prism-logo-2024.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      meta={[
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: pageTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:image', content: image },
        { property: 'og:image:alt', content: 'Atala Prism' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Atala Prism' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@InputOutputHK' },
        { name: 'twitter:creator', content: '@InputOutputHK' },
        { name: 'twitter:title', content: pageTitle },
        { name: 'twitter:description', content: metaDescription },
        { name: 'twitter:image', content: image },
        { name: 'twitter:image:alt', content: 'Atala Prism' },
        { name: 'twitter:url', content: siteUrl },
      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="256x256" href="/favicon-256x256.png" />
      <link rel="icon" type="image/png" sizes="256x256" href="/favicon-256x256.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/favicon-256x256.png" />
      <meta name="theme-color" content="#ffffff" />
      <script charSet="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
    </Helmet>
  );
}

export default SEO;
